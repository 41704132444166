<template>
  <a :href="video.url" class="landing__video video" :class="[className, {'video_first': isFirst}]">
    <div class="video__thumb-container">
      <img :src="videoThumb" alt="thumb" class="video__thumb">
    </div>
    <div class="video__info">
      <h2 class="video__title">{{ video.title }}</h2>
      <p class="video__description">{{ video.description }}</p>
    </div>
  </a>
</template>

<script>
export default {
  name: 'VideoComponent',
  props: {
    video: {
      type: Object,
      default: () => {}
    },
    className: {
      type: String,
      default: ''
    },
    isFirst: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    videoThumb () {
      return this.video.thumb || this.video.localThumb || ''
    }
  }
}
</script>

<style lang="scss" src="./Video.scss" />
